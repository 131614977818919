const ANCHOR_SIDEBAR_SELECTOR = '.js-anchor-sidebar'
const LIST_SECTION = '.js-list-section'
const ANCHOR_SELECTOR = '.js-anchor'
const ANCHOR_FIRST_CLASS = 'anchored-first'
const ACTIVE_CLASS = 'is-active'
const WYSIWYG_CONTENT_SELECTOR = '.js-wysiwyg-content'
const HEADER_SELECTOR = '.js-header'
const DOWNLOAD_PDF_SELECTOR = '.js-download-pdf'
const ACTIVE_DOWNLOAD_CLASS = 'is-download'

class ArticleMain extends HTMLElement {
  constructor () {
    super()

    this.anchorEls = this.querySelectorAll('h2')
    this.anchorSidebarEl = this.querySelector(ANCHOR_SIDEBAR_SELECTOR)
    this.listSection = this.querySelector(LIST_SECTION)
    this.downloadPdf = this.querySelector(DOWNLOAD_PDF_SELECTOR)

		if (this.anchorEls && this.anchorEls.length && this.anchorSidebarEl) {
      this.anchorSidebarEl.classList.remove('hidden')
      this.handlePositionSection(this.anchorEls)
    }

    if (this.downloadPdf) {
      this.handleDownloadPdf(this.downloadPdf)
    }
  }

  handleDownloadPdf (el) {
    const downloadEl = el.querySelector('[download]')
    if (downloadEl) {
      downloadEl.addEventListener('click', () => {
        setTimeout(() => {
          downloadEl.classList.add(ACTIVE_DOWNLOAD_CLASS)
        }, 500)
      })
    }
  }

  handlePositionSection (sections) {
    sections.forEach((section, index) => {
      section.setAttribute('id', `anchor-${index}`)
    })
    this.initAnchor()
  }

	initAnchor() {
		const sections = Array.prototype.slice.call(this.querySelectorAll('h2'));

    if (sections.length) {
      sections.map((el, index) => {
        if (el.id != '') {
          const itemEl = document.createElement('li')
          itemEl.className = 'text-[var(--fs-body-60)]'
          let aClass = "js-anchor ff-body fs-body-75 article__left-content-link font-normal underline underline-offset-2 hover:no-underline transition-all"
          const innerText = new DOMParser().parseFromString(el.innerHTML, 'text/html').body.innerText
          itemEl.innerHTML = `<a href="#${el.id}" arial-label="Anchor Navigation" role="button" class="${aClass}">${innerText}</a>`
          this.listSection && this.listSection.appendChild(itemEl)
        }
      })
      this.selectAnchor()
    }
  }

	selectAnchor() {
    const anchorEls = this.querySelectorAll(ANCHOR_SELECTOR)
    if (anchorEls.length > 0) {
      anchorEls.forEach((el) => {
        el.addEventListener('click', (event) => {
					event.preventDefault()
          this.initActive(anchorEls)
          this.scrollToSection(el)
          el.classList.add(ACTIVE_CLASS)
        })
      })
    }
  }

	initActive(els) {
    els.forEach(el => {
      el.classList.remove(ACTIVE_CLASS)
      if (el.classList.contains(ANCHOR_FIRST_CLASS)) {
        el.classList.remove(ANCHOR_FIRST_CLASS)
      }
    })
  }

  scrollToSection (aEl) {
    const section = document.querySelector(aEl.hash);
    const fromTop = window.scrollY;

    if(section) {
      const sectionOffsetTop = section.getBoundingClientRect().top + fromTop
      const headerEl = document.querySelector(HEADER_SELECTOR);
      const offsetTop = ((headerEl && headerEl.offsetHeight) || 0)
      const top = sectionOffsetTop - offsetTop;

      window.scrollTo({
        top: top,
        behavior: 'smooth'
      })
    }
  }
}

customElements.define('article-main', ArticleMain)
